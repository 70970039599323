import React, { useEffect, useState } from 'react';
import NavbarView from "./NavbarView";
import './styles/Login.css';
import banner1 from '../imgs/banner1.png';
import banner2 from '../imgs/banner2.png';

function Home() {
  const [active, setActive] = useState(false)

  useEffect(() => {
    const fetch = async () => {
      setTimeout(() => {
        setActive(active ? false : true)
      }, 400)

    }
    fetch()
  }, [active])


  return (
    <div className="Login">
      <NavbarView />

      <div className="divHome" >
        <img alt="banner" src={banner1} className="banner" />
      </div>

      <button
        className={active ? "thankyou_button_active" : "thankyou_button"}
      >
        Quero minha Mina Brush!
      </button>

      <div className="divHome2">

        <h2 style={{ color: 'blue', textAlign: 'center' }}>Você não escova os dentes com o dedo, não é mesmo?</h2>
        <h4 style={{ color: 'fuchsia', textAlign: 'center' }}>Então, por que você acha que seus cabelos e couro cabeludo fica limpo assim?</h4>
      </div>

      <div className="divHome3">
        <div>

          <img alt="banner" src={banner2} className="banner2" />

          <h2 style={{ color: 'black', textAlign: 'center', marginTop: '-65px', marginLeft: '50px' }}>OS CUIDADOS COM SEU CABELO COMEÇAM PELA RAIZ!</h2>
          <h5 style={{ color: 'black', textAlign: 'center' }}>Se você sofre com queda de cabelo, frizz, ressecamento, pontas duplas, raiz oleosa, crescimento lento, cabelos quebradiços ou até com caspa,
            significa que seu couro cabeludo não está limpo o suficiente.</h5>
          <br />
          <h5 style={{ color: 'black', textAlign: 'center' }}>Não aprendemos a higienizar o couro cabeludo de forma correta durante o banho.</h5>
          <br />
          <h5 style={{ color: 'black', textAlign: 'center' }}> Lavá-lo com as pontas dos dedos não retira o excesso de gordura, resíduos de produtos, poluição, nem os fungos e bactérias.</h5>
          <br />
          <h5 style={{ color: 'black', textAlign: 'center' }}>Todo esse acúmulo na raiz entope os poros do couro cabeludo, causando diversos problemas e até doenças, prejudicando a saúde e beleza do seu cabelo.Você não escova os dentes com o dedo, não é mesmo?</h5>
        </div>
      </div>

      <button
        className={active ? "thankyou_button_active" : "thankyou_button"}
      >
        Quero minha Mina Brush!
      </button>
    </div >
  );
}

export default Home;
