import React, { Component } from 'react';
import { Route } from 'react-router-dom';

class ProtectedRoute extends Component {
    render() {
        const { component: Component, ...props } = this.props
        return (
            <Route
                {...props}
                render={props => (
                    <Component {...props} />
                )}
            />
        )
    }
}

export default ProtectedRoute;