import React from 'react';
import ProtectedRoute from './components/ProtectedRoute';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'

import { routes, fallbackRoute } from './routes'

import './App.css';

function App() {

	const routeComponents = routes.map((route, key) => {
		if (route.protected) {
			return <ProtectedRoute path={'/market' + route.path} exact={route.exact} component={route.component} />
		} else {
			return <Route path={'/market' + route.path} exact={route.exact} component={route.component} />
		}
	});

	return (
		<div className="App">
			<Router>
				<Switch>
					{routeComponents}
					<ProtectedRoute component={fallbackRoute} />
				</Switch>
			</Router>
		</div>
	);
}

export default App;
