import React from "react";
import { Navbar, Nav } from "react-bootstrap";
import logo from '../imgs/logo.png';


const NavbarView = () => {
  return (
    <>
      <Navbar className='Navbar' fixed="top">
        {/* Top left corner button that displays text */}
        {/* <Navbar.Brand href="#home">{name}</Navbar.Brand> */}

        {/* Top left corner button that displays an image */}
        <Navbar.Brand href="#home">
          <img
            src={logo}
            width="140"
            height="85"
            // className="d-inline-block align-top"
            alt="logo"
          />

        </Navbar.Brand>
        <h2>Mina Brush<span>&trade;</span></h2>

        <Nav className="ml-auto">
          <Nav.Link style={{ color: 'black', alignSelf: 'rigth' }} href="/market/rastreio">Rastreie seu pedido</Nav.Link>

          {/* <Nav.Link href="#projects">Projects</Nav.Link> */}
          {/* <Nav.Link href="#contact">Contact</Nav.Link> */}
        </Nav>


      </Navbar>


    </>
  );
};

export default NavbarView;
