import Home from '../pages/Home';

export const routes = [
    {
        path: '/home',
        component: Home,
        protected: false,
        exact: true,
        checkPermission: false,
        register: false
    },

];

export const fallbackRoute = Home

